import { ChangeEvent, DetailedHTMLProps, FC, InputHTMLAttributes, useEffect, useState } from 'react';
import './EPGFilter.css';
import DatePicker from 'react-date-picker';
import DateRangePicker from 'src/Widgets/common/basicElements/DateRangePicker/DateRangePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import SelectAsync from 'src/Widgets/common/basicElements/SelectAsync/SelectAsync';
import { useAppSelector } from 'src/redux/hooks';
import { Channel } from 'src/@types/shared.types';
import { EPGChannel, EPGFilterProps, FilterOption, Filters } from './EPGFilter.types';


const EPGFilter: FC<EPGFilterProps> = ({
    channels,
    searchForContent,
    onChannelChange,
    onDateChange,
    hour,
    minute,
    date,
    archive,
    timezone,
    onMinuteButtonClick,
    onMinuteChange,
    onHourChange,
    selectedChannel
}) => {

    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filters, setFilters] = useState({});
    const [filteredChannels, setFilteredChannels] = useState<Channel[]>([]);

    const { t } = useTranslation();

    const me = useAppSelector((state) => state.me);
    const matomo = useAppSelector((state) => state.matomo);
    const theme = useAppSelector((state) => state.theme);

    useEffect(() => {
        if (JSON.stringify(channels) !== JSON.stringify(filteredChannels)) {
            setFilteredChannels(channels);
        }
    }, [channels]);

    useEffect(() => {
        if (searchTerm.length > 0) {
            searchForContent(searchTerm, dateRange[0], dateRange[1]);
        }
    }, [searchTerm, dateRange, searchForContent]);

    const onFilterChange = (option: FilterOption, key: keyof Filters) => {
        setFilters((prevFilters: Filters) => {
            const newFilters = { ...prevFilters };

            if (option != null) {
                newFilters[key] = option.value;
            } else {
                delete newFilters[key];
            }

            setFilteredChannels(
                channels.filter((channel: Channel) => {
                    for (const filterKey in newFilters) {
                        if (channel[filterKey] !== newFilters[filterKey]) {
                            return false;
                        }
                    }
                    return true;
                })
            );

            return newFilters;
        });
    };

    const channelChangeHandler = (selectedChannel: EPGChannel) => {
        onChannelChange(selectedChannel, date);
    };

    const dateChangeHandler = (date: Date) => {
        onDateChange(date);
    };

    const onDateRangeChange = (newDateRange: Date[]) => {
        setDateRange(newDateRange);
        onProgramSearch(newDateRange);
    };

    const onProgramSearch = (dateRange: Date[]) => {
        if (searchTerm.length > 0) {
            searchForContent(searchTerm, dateRange[0], dateRange[1]);
        }
    };

    const onProgramSearchKeyPress = (e: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
        if (e.key === 'Enter') {
            matomo.push(['trackEvent', 'goto', 'programSearchbarEnter']);
            onProgramSearch([]);
        }
    };

    const hourChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        onHourChange({ ...e });
    };

    const minuteChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        onMinuteChange({ ...e });
    };

    const onSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const minuteButtonClickHandler = () => {
        onMinuteButtonClick(undefined);
    };

    let selectedChannelValue = selectedChannel;

    const isGoButtonDisabled = !selectedChannel || hour === undefined || minute === undefined;

    return (
        <div className="epg-filter">
            <div className="epg-sidebar-comboboxes">
                {// @ts-ignore
                //! Convert ignored componenets into typescript
                    <SelectAsync
                    loading={!filteredChannels.length}
                    spinnerText={t('Loading Countries')}
                    placeholder={t('All Countries')}
                    isClearable
                    escapeClearsValue
                    isSearchable
                    options={filteredChannels
                        .map((channel) => channel.country)
                        .filter((value, index, self) => self.indexOf(value) === index)
                        .sort()
                        .map((c) => ({ value: c, label: c }))}
                    onChange={(option: FilterOption) => {
                        matomo.push(['trackEvent', 'goTo', 'selectCountry']);
                        onFilterChange(option, 'country');
                    }}
                />}
                {// @ts-ignore
                    <SelectAsync
                    loading={!filteredChannels.length}
                    spinnerText={t('Loading Mediatypes')}
                    placeholder={t('All Mediatypes')}
                    isClearable
                    escapeClearsValue
                    isSearchable
                    options={filteredChannels
                        .map((channel) => channel.type)
                        .filter((value, index, self) => self.indexOf(value) === index)
                        .sort()
                        .map((t) => ({ value: t, label: t }))}
                    onChange={(option: FilterOption) => onFilterChange(option, 'type')}
                />}
                {// @ts-ignore
                    <SelectAsync
                    loading={!filteredChannels.length}
                    spinnerText={t('Loading Channels')}
                    placeholder={t('Select Channel')}
                    className={[theme.borderPrimary, 'select-channel'].join(' ')}
                    value={selectedChannelValue}
                    isClearable
                    escapeClearsValue
                    isSearchable
                    options={filteredChannels
                        .map((c) => ({
                            value: c.id,
                            label: c.name,
                            mediaType: c.mediaType
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label))}
                    onChange={(option: EPGChannel) => {
                        matomo.push(['trackEvent', 'goto', 'selectedChannelFilter']);
                        channelChangeHandler(option ?? null);
                    }}
                />}
            </div>

            <hr className="epg-divider" />
            <div className="epg-sidebar-container">
                <DatePicker
                    onChange={dateChangeHandler}
                    value={date}
                    className="datepicker-full-width"
                    maxDate={new Date()}
                    minDate={
                        archive !== false && me.archive
                            ? me.archive.start
                            : undefined
                    }
                    disabled={selectedChannel === null ? true : undefined}
                />
                <div className="epg-selection-container">
                    <label htmlFor="hour-selection" className="epg-selection-label">
                        {t('Scroll to hour')}
                    </label>
                    <input
                        id="hour-selection"
                        type="number"
                        min="0"
                        max="23"
                        value={hour}
                        onChange={hourChangeHandler}
                    />
                    {/* TODO: add correct timezone */}
                    <label htmlFor="hour-selection">({timezone})</label>
                </div>
                <div className="epg-selection-container">
                    <label htmlFor="minute-selection" className="epg-selection-label">
                        {t('View Minute')}
                    </label>
                    <input
                        id="minute-selection"
                        type="number"
                        min="0"
                        max="59"
                        value={minute}
                        onChange={minuteChangeHandler}
                    />
                    <Button
                        type="secondary"
                        btnClass={'epg-minute-button'}
                        onClick={minuteButtonClickHandler}
                        key="minute-go-btn"
                        title={
                            isGoButtonDisabled
                                ? t('Select a channel, hour and minute first')
                                : ''
                        }
                        disabled={isGoButtonDisabled}
                    >
                        {t('Go!')}
                    </Button>
                </div>
            </div>

            <hr style={{ visibility: 'hidden' }} className="epg-divider" />
            <div style={{ visibility: 'hidden' }} className="epg-sidebar-container">
                {
                // @ts-ignore
                <DateRangePicker
                    onChange={onDateRangeChange}
                    value={dateRange}
                    className="datepicker-full-width"
                    disabled={selectedChannel === null ? true : undefined}
                />}
                {selectedChannel ? (
                    <div className="epg-program-search-container">
                        <button
                            className="search-button"
                            onClick={() => onProgramSearch([])}
                        >
                            <FontAwesomeIcon icon="search" />
                        </button>
                        <input
                            type="text"
                            placeholder={t('Search for program')}
                            className="epg-program-search"
                            id="epg-program-search"
                            value={searchTerm}
                            onChange={onSearchTermChange}
                            onKeyPress={onProgramSearchKeyPress}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default EPGFilter;