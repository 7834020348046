import React, { memo, useCallback, useRef, useState } from 'react';
import styles from './ClipColumn.module.scss';
//@ts-ignore
import AutoSizer from 'react-virtualized-auto-sizer';
//@ts-ignore
import { VariableSizeList as List, areEqual } from 'react-window';
import { useIsScreenSize } from 'src/hooks/useIsScreenSize';
import { ReactWindowProps } from 'src/Widgets/common/MentionResults/MentionResults.types';
//import useChannelIcons from 'src/hooks/useChannelIcons';
import APIMentions from 'src/API/APIMentions';
import { MentionsVisualizerTypes } from '../../MentionsVisualizer.types';
import Skeleton from '../../Skeleton/Skeleton';
import ClipCard from '../../ClipCard/ClipCard';
import { useTranslation } from 'react-i18next';

import Button from 'src/Widgets/common/basicElements/Button/Button';
import APIClips from 'src/API/APIClips';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faList } from '@fortawesome/free-solid-svg-icons';
import APIChannels from 'src/API/APIChannels';
//import { sleep } from 'src/Widgets/common/helpers';

const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

const ClipColumn = ({
    listKey,
    filteredDataToDisplay,
    isSearch,
    processedIndices,
    fetchedItems,
    setFetchedItems,
    clipDetail,
    setClipDetail,
    isEdited,
    isEditorial,
    activeFilter,
    isMentionFindrApp,
    backFillSelectionVisible,
    setBackFillSelectionVisible,
    clipStates,
    updateClipState,
    fetchClipStates,
    stateUpdatesObserver,
    wFeedAbortCtrl,
    messageHandler,
}: MentionsVisualizerTypes.ClipColumnProps) => {
    const ongoingRequests: Set<number> = new Set();
    const isMobile = useIsScreenSize(1280);
    const { t } = useTranslation();

    const [selectedCardElements, setSelectedCardElements] = useState<any[]>([]);
    const [movedItemsMessage, setMovedItemsMessage] = useState<string>('');

    const rowHeights = useRef<{ [key: number]: number }>({});

    const fetchNotificationDetails = async (notifID: number) => {
        if (
            !clipDetail[notifID] &&
            !fetchedItems.has(notifID) &&
            notifID !== undefined &&
            !ongoingRequests.has(notifID)
        ) {
            ongoingRequests.add(notifID);
            try {
                const res = await APIMentions.getNotificationResults(
                    isSearch ? notifID + 't' : notifID
                );
                setClipDetail((prevData: MentionsVisualizerTypes.ClipDetailType) => ({
                    ...prevData,
                    [notifID]: res.data
                }));
                setFetchedItems((prevItems: Set<number>) => {
                    const newItems = new Set(prevItems);
                    newItems.add(notifID);
                    return newItems;
                });
            } finally {
                ongoingRequests.delete(notifID);
            }
        }
    };

    const handleSelectAllCheckboxes = () => {
        if (selectedCardElements.length === filteredDataToDisplay.length) {
            setSelectedCardElements([]);
        } else {
            setSelectedCardElements(filteredDataToDisplay);
        }
    };

    //fetch notification details when scrolling
    let stateObserverCalled = false;

    const handleItemsRendered = debounce(
        ({ visibleStartIndex, visibleStopIndex }: any) => {
            const notifIDsDetailsToFetch = [];
            const notifIDsStatesToFetch = [];
    
            // Loop through the visible range of items
            for (let index = visibleStartIndex; index <= visibleStopIndex; index++) {
                const item = filteredDataToDisplay[index];
                notifIDsStatesToFetch.push(item.notifID);
    
                // Fetch details for the super notification
                if (!fetchedItems.has(item.notifID) && !processedIndices.has(index)) {
                    processedIndices.add(index);
                    notifIDsDetailsToFetch.push(item.notifID);
                }
    
                // Check for basicNotifications and fetch their details as well
                if (item.basicNotifications && item.basicNotifications.length > 0) {
                    item.basicNotifications.forEach((basicNotification: any) => {
                        if (!fetchedItems.has(basicNotification.notifID)) {
                            notifIDsDetailsToFetch.push(basicNotification.notifID);
                        }
                    });
                }
            }
    
            // Fetch details for each notifID in the array
            notifIDsDetailsToFetch.forEach((notifID) => {
                const validNotifID: string | number = notifID;
                fetchNotificationDetails(validNotifID as any);
            });
    
            if (notifIDsStatesToFetch.length > 0 && 
                isEditorial && clipStates.cl) {
                const initialClipState = clipStates.cl;
                wFeedAbortCtrl.current.abort();
                wFeedAbortCtrl.current = new AbortController();
                fetchClipStates(notifIDsStatesToFetch)
                    .then(() => {
                        if (clipStates.cl === initialClipState && !stateObserverCalled) {
                            stateObserverCalled = true;
                            stateUpdatesObserver(clipStates.cl);
                            stateObserverCalled = false;
                        }
                    })
                    .catch((error: any) => {
                        if (error.name !== 'AbortError') {
                            console.error('Failed to fetch clip states:', error);
                        }
                    });
            } 
            // else if (clipStates.cl) {
            //     console.log("scrolling up, aborting wFeed")
            //     wFeedAbortCtrl.current.abort();
            //     wFeedAbortCtrl.current = new AbortController();
                
            //     sleep(500).then(() => {
            //         console.log("run wFeed")
            //         stateObserverCalled = true;
            //         stateUpdatesObserver(clipStates.cl, true);
            //         stateObserverCalled = false;
            //     });
            // }
        },
        300
    );

     // Dynamically calculate the height of each row
     const getRowHeight = useCallback(
        (index: number) => {
            const clip = filteredDataToDisplay[index];
            const defaultHeight = isMobile ? 350 : 130; // Default height for a card

            // Calculate height based on content if details are available
            if (clipDetail[clip.notifID]) {

                const descriptionHeight = clipDetail[clip.notifID].desc
                    ? clipDetail[clip.notifID].desc.length * 0.1
                    : 0;

                const basicNotificationsHeight = clip.basicNotifications
                    ? clip.basicNotifications.length * 1
                    : 0;

                return defaultHeight + descriptionHeight + basicNotificationsHeight;
            }

            return defaultHeight;
        },
        [clipDetail, filteredDataToDisplay, isMobile]
    );

    //keyframe row component - react window
    const KeyframeRow = memo(({ index, style }: ReactWindowProps) => {

        const clip = filteredDataToDisplay[index];
        const clipEditorialState = clipStates?.states?.find(
            (state: { id: string; state: string, userid: string }) => Number(state.id) === clip.notifID
        ) || { state: '' , id: '', userid: ''};

        const clipDetailData = clipDetail[clip.notifID];
        if (!clipDetailData) {
            return (
                <div style={style} key={clip.channelid}>
                    <Skeleton width="100%" height="120px" isClipCard />
                </div>
            );
        }

        const basicNotificationDetails = clip.basicNotifications?.map(
            (basicNotif: any) => clipDetail[basicNotif.notifID] || {}
        );
        rowHeights.current[index] = getRowHeight(index);
        return (
            <ClipCard
                clip={clip}
                clipEditorialState={clipEditorialState}
                clipStates={clipStates}
                clipDetailData={{
                    ...clipDetailData,
                    icon: APIChannels.getIconUrl(clip.channelNumber)
                }}
                basicNotifications={basicNotificationDetails}
                style={style}
                isEdited={isEdited}
                isEditorial={isEditorial}
                activeFilter={activeFilter}
                isMentionFindrApp={isMentionFindrApp}
                backFillSelectionVisible={backFillSelectionVisible}
                setSelectedCardElements={setSelectedCardElements}
                selectedCardElements={selectedCardElements}
                updateClipState={updateClipState}
                messageHandler={messageHandler}
            />
        );
    }, areEqual);

    if (filteredDataToDisplay.length === 0) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}
            >
                {t('Selected channels not in')}
                {activeFilter.type}
            </div>
        );
    }

    const backFillResultsToNotifications = async () => {
        //if the selected clips are from search query group, make them permanent
        if (isSearch) {
            const notifIDs = selectedCardElements.map(
                (item) => item.notifID || item.notifid
            );
            const res = await APIClips.makeClipsPermanent(
                filteredDataToDisplay[0].profileID,
                notifIDs
            );

            const notMovedItems = selectedCardElements.length - res.data.moved;
            if (res.data.moved && res.rc === 0) {
                setMovedItemsMessage(
                    notMovedItems > 0
                        ? `${notMovedItems} ${t('clips already moved')}. ` +
                              `${res.data.moved} ${t('clips moved to notifications')}`
                        : `${res.data.moved} ${t('clips moved to notifications')}`
                );
            } else if (res.data.moved === 0) {
                setMovedItemsMessage(
                    `${t('No clips moved to notifications')}, ${t('clips already moved')}`
                );
            } else {
                setMovedItemsMessage('');
            }

            setTimeout(() => {
                res.data.moved > 0 && setSelectedCardElements([]);
                setMovedItemsMessage('');
                res.data.moved > 0 && setBackFillSelectionVisible(false);
            }, 2000);
        }
    };

    return (
        <div
            className={
                backFillSelectionVisible
                    ? styles.clipsContentContainerBackFill
                    : styles.clipsContentContainer
            }
            data-testid="keyframe-row"
        >
            {backFillSelectionVisible && (
                <div className={styles.backFillSelectContainer}>
                    <div
                        onClick={handleSelectAllCheckboxes}
                        className={styles.backFillSelectAll}
                    >
                        <input
                            type="checkbox"
                            className={styles.checkbox}
                            checked={
                                selectedCardElements.length ===
                                filteredDataToDisplay.length
                            }
                            onChange={(e) => e.stopPropagation()}
                        />
                        <FontAwesomeIcon
                            icon={faCheckDouble}
                            className={styles.selectAllIcon}
                            title={t('Select all')}
                        />
                        <div
                            className={
                                selectedCardElements.length !== 0
                                    ? styles.selectedCount
                                    : styles.selectedCountNotAllSelected
                            }
                        >
                            {selectedCardElements.length}/{filteredDataToDisplay.length}{' '}
                            {t('Selected')}
                        </div>
                    </div>
                    {selectedCardElements.length > 0 ? (
                        <div className={styles.backFillButtons}>
                            {movedItemsMessage ? (
                                <span className={styles.movedItemsMessage}>
                                    {movedItemsMessage}
                                </span>
                            ) : (
                                <Button
                                    type={'secondary'}
                                    btnClass={styles.backFillButton}
                                    disabled={selectedCardElements.length === 0}
                                    onClick={backFillResultsToNotifications}
                                >
                                    {t('Backfill')}
                                </Button>
                            )}
                            <Button
                                type={'danger'}
                                btnClass={styles.backFillButton}
                                disabled={selectedCardElements.length === 0}
                                onClick={() => {
                                    setSelectedCardElements([]);
                                    setBackFillSelectionVisible(false);
                                }}
                            >
                                {'Cancel'}
                            </Button>
                        </div>
                    ) : (
                        <span className={styles.backFillMessage}>
                            {t('Please select clips to backfill')}
                        </span>
                    )}
                </div>
            )}
            <AutoSizer>
                {({ height, width }: { height: number; width: number }) => (
                    <List
                        key={listKey}
                        height={height}
                        itemCount={filteredDataToDisplay.length}
                        itemSize={(index: number) => getRowHeight(index)}
                        width={width}
                        onItemsRendered={handleItemsRendered}
                        ref={(list: any) => (list && list.resetAfterIndex(0))}
                    >
                        {KeyframeRow}
                    </List>
                )}
            </AutoSizer>
        </div>
    );
};

export default ClipColumn;
